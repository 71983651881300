<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>课堂</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/checkCourse' }">打卡课程</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-form :rules="rules" ref="form" :model="form" style="padding: 48px 72px" label-position="right"
            label-width="auto">
            <el-form-item label="课程名称: " prop="name">
                <el-input v-model="form.name" style="width: 240px" autofocus clearable />
            </el-form-item>

            <el-form-item label="类型" prop="type">
                <el-radio-group v-model="form.type" size="mini">
                    <el-radio-button :label="1">视频</el-radio-button>
                    <el-radio-button :label="2">图文</el-radio-button>
                    <el-radio-button :label="3">题库</el-radio-button>
                    <el-radio-button :label="4">批注</el-radio-button>
                    <el-radio-button :label="5">音频</el-radio-button>
                    <el-radio-button :label="6">习题</el-radio-button>
                    <el-radio-button :label="7">表达</el-radio-button>
                    <el-radio-button :label="81">好词</el-radio-button>
                    <el-radio-button :label="82">好句</el-radio-button>
                    <el-radio-button :label="9">知识扩展</el-radio-button>
                    <el-radio-button :label="10">朗读</el-radio-button>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input v-model="form.sort" style="width: 240px" />
            </el-form-item>

            <template v-if="form.type === 1">
                <el-form-item label="选择视频：" prop="video_id">
                    <selectVideo @onSelect="onSelect" @onDelete="onDelete"></selectVideo>
                </el-form-item>

                <el-form-item label="视频封面：" prop="pic">
                    <el-image :src="form.pic" class="videoImage" v-if="form.pic" />
                </el-form-item>
            </template>
            <template v-else-if="form.type === 3">
                <el-form-item label="选择题库: " prop="video_id">
                    <selectQuestionBank @onSelect="onSelectItem" @onDelete="onDeleteItem"></selectQuestionBank>
                </el-form-item>
            </template>
            <template v-else-if="form.type === 4">
                <el-form-item label="选择批注: " prop="video_id">
                    <selectAnnotation @onSelect="onSelectItem" @onDelete="onDeleteItem"></selectAnnotation>
                </el-form-item>
            </template>
            <template v-else-if="form.type === 5">
                <el-form-item label="选择音频: " prop="video_id">
                    <selectAudio @onSelect="onSelectItem" @onDelete="onDeleteItem"></selectAudio>
                </el-form-item>
                <el-form-item label="封面图" prop="pic">
                    <cover :uploadPath="audioUploadPath" @success="uploadSuccess"></cover>
                </el-form-item>
                <el-form-item label="音频内容" prop="content">
                    <el-input resize="none" type="textarea" :rows="10" style="width: 40%;" v-model="form.content"
                        placeholder="请输入音频内容">
                    </el-input>
                </el-form-item>
            </template>
            <el-form-item label="状态: " prop="status">
                <el-switch :active-value="1" :inactive-value="0" v-model="form.status" style="width: 240px" />
            </el-form-item>

            <template v-if="form.type === 7">
                <el-radio-group v-model="expression" class="margin-sm">
                    <el-radio :label="2">图文</el-radio>
                    <el-radio :label="1">视频</el-radio>
                </el-radio-group>
                <blockquote v-if="expression === 1">
                    <el-form-item label="选择视频：" prop="video_id">
                        <selectVideo @onSelect="onSelect" @onDelete="onDelete"></selectVideo>
                    </el-form-item>
                    <el-form-item label="视频封面：" prop="pic">
                        <el-image :src="form.pic" class="videoImage" v-if="form.pic" />
                    </el-form-item>
                </blockquote>
                <el-form-item label="内容" prop="content" v-if="expression === 2">
                    <editor :content="form.content" @onChange="onChange" />
                </el-form-item>
                <el-form-item label="解析" prop="analyse">
                    <el-input type="textarea" :rows="5" :maxlength="255" :show-word-limit="true" resize="no"
                        style="width:40%" placeholder="请输入内容" v-model="form.analyse">
                    </el-input>
                </el-form-item>
            </template>

            <template v-if="form.type === 81" prop="video_id">
                <el-form-item label="选择好词: " prop="video_id">
                    <selectKnowledge1 @onSelect="onSelectItem" @onDelete="onDeleteItem"></selectKnowledge1>
                </el-form-item>
            </template>
            <template v-if="form.type === 82" prop="video_id">
                <el-form-item label="选择好句: " prop="video_id">
                    <selectKnowledge1 @onSelect="onSelectItem" @onDelete="onDeleteItem"></selectKnowledge1>
                </el-form-item>
            </template>
            <template v-if="form.type === 9" prop="video_id">
                <el-form-item label="选择知识扩展: " prop="video_id">
                    <selectKnowledge2 @onSelect="onSelectItem" @onDelete="onDeleteItem"></selectKnowledge2>
                </el-form-item>
            </template>
            <template v-if="form.type === 2 ">
                <el-form-item label="课程内容" prop="content">
                    <editor :content="form.content" @onChange="onChange" />
                </el-form-item>
            </template>
            <template v-if="form.type === 10">
                <el-form-item label="朗读内容" prop="content">
                    <editor :content="form.content" @onChange="onChange" />
                </el-form-item>
            </template>

            <el-form-item>
                <el-button size="mini" @click="$router.back()">取消</el-button>
                <el-button size="mini" @click="submit" type="primary">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import selectVideo from '@/components/selectVideo'
import selectQuestionBank from '@/components/selectQuestionBank'
import selectAnnotation from '@/components/selectAnnotation'
import selectAudio from '@/components/selectAudio'
import selectKnowledge1 from '@/components/selectKnowledge1'
import selectKnowledge2 from '@/components/selectKnowledge2'
import editor from '@/components/editor'
import { config } from '@/js/config'
import Cover from '@/components/cover'

import { mapActions } from 'vuex'

export default {
    data: () => ({
        audioUploadPath: "audio",
        loading: false,
        config,

        rules: {
            name: [{ required: true, message: '请输入课程名称', trigger: 'blur' }],
            type: [{ required: true }],
            status: [{ required: true }],
            pic: [{ required: true }],
            notification: [{ required: true }],
            amount: [{ required: true }],
            indate: [{ required: true }],
            content: [{ required: true }],
            analyse: [{ required: true }],
            sort: [{ required: true }],
            video_id: [{ required: true }]
        },
        courseId: 0,
        options: [],
        questionBankList: [],
        selectGrade: [],
        form: {
            name: '',
            pic: '',
            status: 1,
            check_course_plan_id: '',
            size: '',
            sort: 0,
            duration: '',
            video_id: '',
            type: 1,
            content: '',
            analyse: '',
            knowledge_type: 0
        },
        expression: 2
    }),

    components: { selectVideo, editor, selectQuestionBank, selectAnnotation, selectAudio, Cover, selectKnowledge1, selectKnowledge2 },

    methods: {
        ...mapActions('punch', ['addPunch', 'addCheck', 'getPunchDetail']),

        onChange(content) {
            this.form.content = content
        },
        submit() {

            this.$refs.form.validate(async valid => {
                if (!valid) return
                else {
                    this.loading = true
                    if (this.form.type === 2) {
                        delete this.form.size
                        delete this.form.duration
                        delete this.form.video_id
                    }

                    const { id } = this.$route.query

                    const params = { ...this.form }

                    if ([81, 82].includes(this.form.type)) {
                        if (this.form.type === 81) params.knowledge_type = 1
                        if (this.form.type === 82) params.knowledge_type = 2
                        params.type = 8
                    }

                    if (id) params.id = id

                    const result = await this.addCheck(params)

                    this.loading = false

                    this.$message.success('添加成功')

                    this.$router.push('/checkCourse/detail/' + this.courseId + '/manage?planId=' + this.form.check_course_plan_id)
                }
            })
        },

        onSelect({ size, pic, id, duration }) {
            this.form.size = size
            this.form.pic = pic
            this.form.video_id = id
            this.form.duration = duration
        },

        uploadSuccess(path) {
            this.form.pic = path
        },

        onDelete() {
            this.form.size = ''
            this.form.pic = ''
            this.form.video_id = ''
            this.form.duration = ''
        },

        // 题库/批注
        onSelectItem(id) {
            this.form.video_id = id
        },
        onDeleteItem() {
            this.form.video_id = ''
        }
    },

    async created() {
        let planId = this.$route.params.planId
        this.courseId = this.$route.params.courseId

        this.form.check_course_plan_id = planId
        if (this.$route.query.id && this.$route.query.type !== 'new') {
            this.loading = true

            const { data } = await this.getPunchDetail({
                id: planId
            })

            this.form = data

            this.loading = false
        }
    }
}
</script>
